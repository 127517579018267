import { useMemo } from 'react'
import { useRouter } from 'next/router'
import {
  OrganizationJsonLd,
  FAQPageJsonLd,
  BreadcrumbJsonLd,
  ImageJsonLd,
} from 'next-seo'
import { delHtmlTag } from '@lib/utils/tools'
import { tagsToCollection } from '@lib/utils/thirdparty'

// 优化后的代码
const JsonLd = ({ type, product, metafields, slug }) => {
  const { locale } = useRouter()

  const followCollection = useMemo(() => {
    if (type === 'product' && product) {
      return tagsToCollection(product.tags)
    }
  }, [product, type])

  const faqItems = useMemo(() => {
    return metafields?.components?.faq?.items?.map((item) => ({
      questionName: delHtmlTag(item.question || '').replace(/"/g, '\\"'),
      acceptedAnswerText: delHtmlTag(item.answer || '').replace(/"/g, '\\"'),
    }))
  }, [metafields])

  const baseUrl = `https://www.eufy.com${locale === 'us' ? '' : `/${locale}`}`
  const productBaseUrl = `${baseUrl}/products/${product?.handle}`
  const collectionBaseUrl = `${baseUrl}/collections/${
    followCollection?.handle || slug
  }`

  const breadcrumbsJsonLdName =
    type === 'collections' ? metafields.breadcrumbsJsonLdName : null
  const breadcrumbsJsonLdUrl =
    type === 'collections'
      ? `${baseUrl}/collections/${metafields.belongsToCollectionHandle}`
      : null

  return (
    <>
      <OrganizationJsonLd
        type="Corporation"
        id="https://www.eufy.com/"
        logo="https://www.eufy.com/icon.png"
        legalName="Anker Innovations"
        name="eufy"
        sameAs={[
          'https://www.facebook.com/EufyOfficial',
          'https://twitter.com/eufyofficial',
          'https://www.youtube.com/@eufy',
          'https://www.instagram.com/eufylife/',
        ]}
        url="https://www.eufy.com/"
      />
      {type === 'product' && faqItems && (
        <FAQPageJsonLd mainEntity={faqItems} />
      )}
      {type === 'product' && product && (
        <BreadcrumbJsonLd
          itemListElements={[
            { position: 1, name: 'Home', item: baseUrl },
            {
              position: 2,
              name: followCollection?.name,
              item: collectionBaseUrl,
            },
            { position: 3, name: product.name, item: productBaseUrl },
          ]}
        />
      )}
      {type === 'product' &&
        product &&
        metafields?.components?.hasImgJsonLd && (
          <ImageJsonLd
            contentUrl={product.images[0]?.url}
            license="https://example.com/license"
            creditText="eufy"
            acquireLicensePage={productBaseUrl}
            creator={{
              '@type': 'Organization',
              name: 'eufy',
            }}
            copyrightNotice="eufy"
          />
        )}
      {type === 'collections' && breadcrumbsJsonLdName && slug && (
        <BreadcrumbJsonLd
          itemListElements={[
            { position: 1, name: 'Home', item: baseUrl },
            {
              position: 2,
              name: breadcrumbsJsonLdName,
              item: breadcrumbsJsonLdUrl,
            },
            { position: 3, name: slug, item: collectionBaseUrl },
          ]}
        />
      )}
    </>
  )
}

export default JsonLd
